$feature-flags: (
  ui-shell: true,
  grid-columns-16: true,
);

// Feature flags
$css--font-face: true;
$css--plex: true;

// Global styles
@import 'carbon-components/scss/globals/scss/css--font-face';
@import 'carbon-components/scss/globals/grid/grid';

// Carbon components
@import 'carbon-components/scss/components/breadcrumb/breadcrumb';
@import 'carbon-components/scss/components/button/button';
@import 'carbon-components/scss/components/data-table/data-table';
@import 'carbon-components/scss/components/link/link';
@import 'carbon-components/scss/components/pagination/pagination';
@import 'carbon-components/scss/components/tabs/tabs';
@import 'carbon-components/scss/components/ui-shell/ui-shell';

// Remove overrides once Carbon bugs are fixed upstream.

/// The React <Content /> component uses the `main` element which IE11 doesn't recognize
/// @link https://github.com/carbon-design-system/carbon/issues/2789
main {
  display: block;
}

/// Need grid option to not add page gutters at large viewports, to also use when nesting grids
/// @link https://github.com/carbon-design-system/carbon/issues/2792
@media (min-width: 42rem) {
  .bx--grid--no-gutter {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

/// Padding was introduced in 10.3.0, needs an option to let grid set the viewport gutter
/// @link https://github.com/carbon-design-system/carbon/issues/3010
.bx--content {
  padding: 0;
}
